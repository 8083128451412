/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'

import { media, typeRamp, colors } from '../../constants'

export const STATS_LAYOUT_2_UP = 'STATS_LAYOUT_2_UP'
export const STATS_LAYOUT_3_UP = 'STATS_LAYOUT_3_UP'

const propTypes = {
	number: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	border: PropTypes.bool,
}

const defaultProps = {
	description: null,
	border: false,
}

const Stat = ({ number, title, description, border }) => {
	const numberChars = number.split('')

	return (
		<div data-comp="stat">
			<div
				css={{
					...typeRamp.light_50,
					marginBottom: '0.5rem',
					...(border
						? {
								borderTop: `1px solid ${colors.border}`,
								paddingTop: '2rem',
						  }
						: null),

					[media.large]: {
						...typeRamp.light_80,
					},
				}}
			>
				{numberChars.map((c, idx) => {
					if (c === '%') {
						return (
							<sup
								key={idx}
								css={{
									...typeRamp.light_40,
									verticalAlign: 'top',
								}}
							>
								{c}
							</sup>
						)
					}
					return (
						<span
							key={idx}
							css={{
								display: 'inline-block',
							}}
						>
							{c}
						</span>
					)
				})}
			</div>
			<div
				css={{
					...typeRamp.med_16,
					'& > * + *': {
						marginTop: '1rem',
					},
				}}
			>
				{title}
			</div>
			{description && (
				<div
					css={{
						paddingTop: '1rem',
						...typeRamp.light_16,
						color: colors.grey,
						'& > * + *': {
							marginTop: '1rem',
						},
					}}
				>
					{description}
				</div>
			)}
		</div>
	)
}

Stat.propTypes = propTypes
Stat.defaultProps = defaultProps

export default Stat
