/** @jsx jsx */
import { useStaticQuery, graphql } from 'gatsby'
import { jsx } from '@emotion/core'
import Img from 'gatsby-image/withIEPolyfill'

import Helmet from '../components/HelmetHelper'
import Container from '../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../components/content/Text'
import Stat from '../components/content/Stat'
import { THEME_PERIWINKLE, media } from '../constants'
import Slide, { SLIDE_LAYOUT_MOCK_FULL_SCREEN } from '../components/Slide'
import {
	LayoutIndented,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
	buildLayoutVCadenceStyle,
	LayoutCol2,
	LAYOUT_V_CADENCE_SIZE_EXTRA_LARGE,
	LAYOUT_V_CADENCE_SIZE_LARGE,
	vCadenceSpaceMap,
} from '../components/content/Layout'
import Highlight from '../components/content/Highlight'
import { Row, Cell } from '../components/Grid'
import { provideAccentTheme } from '../context/AccentThemeContext'
import { InlineLink } from '../components/content/Link'

const CompanyPage = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		query {
			collage: file(relativePath: { eq: "company-collage@2x.jpg" }) {
				...ImageFluidFull
			}
			breakerImage: file(relativePath: { eq: "company-2@2x.jpg" }) {
				...ImageFluidFull
			}
		}
	`)

	return (
		<div>
			<Helmet>
				<title>Company</title>
				<meta content="Company | Free Association" property="og:title" />
				<meta content="Company | Free Association" name="twitter:title" />
				<meta
					name="description"
					content="Our name represents the boundless potential of creative freedom and thoughtful experimentation."
				/>
				<meta
					property="og:description"
					content="Our name represents the boundless potential of creative freedom and thoughtful experimentation."
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--headline">The future is free.</h1>
							<p>
								Our name represents the boundless potential of creative freedom
								and thoughtful experimentation. It reflects our shared spirit of
								endless curiosity, learning and adaptation.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<div
					css={{
						display: 'flex',
						flexDirection: 'column',
						[media.mediumUp]: {
							...buildLayoutVCadenceStyle({
								size: LAYOUT_V_CADENCE_SIZE_MEDIUM,
								cols: 1,
							}),
						},
					}}
				>
					<Container
						style={{
							[media.small]: {
								order: 2,
								...vCadenceSpaceMap[LAYOUT_V_CADENCE_SIZE_LARGE][media.small],
							},
						}}
					>
						<Row
							style={{
								[media.small]: buildLayoutVCadenceStyle({
									size: LAYOUT_V_CADENCE_SIZE_MEDIUM,
									cols: 1,
								}),
							}}
						>
							<Cell
								cols={{
									[media.small]: 12,
									[media.medium]: 5,
									[media.large]: 3,
								}}
								offset={{
									[media.small]: 0,
									[media.medium]: 1,
									[media.large]: 1,
								}}
							>
								<Stat
									number="35"
									border={true}
									title="Teammates"
									description="We’re a humble, tight-knit team that draws energy from big challenges and works hard to make a positive impact in our world."
								/>
							</Cell>
							<Cell
								cols={{
									[media.small]: 12,
									[media.medium]: 5,
									[media.large]: 3,
								}}
								offset={{
									[media.small]: 0,
									[media.medium]: 0,
									[media.large]: '0_half',
								}}
							>
								<Stat
									number="14"
									border={true}
									title="Years Running"
									description="Our company is founded on 20 years of friendship. We support each other like family and build on a foundation of trust, patience and respect."
								/>
							</Cell>
						</Row>
					</Container>
					<Container
						coastToCoastSmall={true}
						style={{
							[media.small]: { order: 1 },
						}}
					>
						<Img fluid={data.collage.childImageSharp.fluid} />
					</Container>
					<Container
						style={{
							[media.small]: {
								order: 3,
								...vCadenceSpaceMap[LAYOUT_V_CADENCE_SIZE_MEDIUM][media.small],
							},
						}}
					>
						<Row
							style={{
								[media.small]: buildLayoutVCadenceStyle({
									size: LAYOUT_V_CADENCE_SIZE_MEDIUM,
									cols: 1,
								}),
							}}
						>
							<Cell
								cols={{
									[media.small]: 12,
									[media.medium]: 5,
									[media.large]: 3,
								}}
								offset={{
									[media.small]: 0,
									[media.medium]: 1,
									[media.large]: '4_half',
								}}
							>
								<Stat
									number="100%"
									border={true}
									title="Remote"
									description="We prioritize talent over location, building remote parity as collaborators across 10 different U.S states."
								/>
							</Cell>
							<Cell
								cols={{
									[media.small]: 12,
									[media.medium]: 5,
									[media.large]: 3,
								}}
								offset={{
									[media.small]: 0,
									[media.medium]: 0,
									[media.large]: 1,
								}}
							>
								<Stat
									number="90%"
									border={true}
									title="Referral Rate"
									description="We’re proud to say our business is built upon referrals from current and former clients. Good work begets good work."
								/>
							</Cell>
						</Row>
					</Container>
					<Container
						style={{
							[media.small]: {
								order: 4,
								...vCadenceSpaceMap[LAYOUT_V_CADENCE_SIZE_MEDIUM][media.small],
							},
						}}
					>
						<Row
							style={{
								[media.small]: buildLayoutVCadenceStyle({
									size: LAYOUT_V_CADENCE_SIZE_MEDIUM,
									cols: 1,
								}),
							}}
						>
							<Cell
								cols={{
									[media.small]: 12,
									[media.medium]: 5,
									[media.large]: 3,
								}}
								offset={{
									[media.small]: 0,
									[media.mediumUp]: 1,
								}}
							>
								<Stat
									number="4"
									border={true}
									title="Average Team Size"
									description="Our lean, highly collaborative teams produce rapid and impactful work. We act as an extension of partner teams and scale up when ready."
								/>
							</Cell>
							<Cell
								cols={{
									[media.small]: 12,
									[media.medium]: 5,
									[media.large]: 3,
								}}
								offset={{
									[media.small]: 0,
									[media.medium]: 0,
									[media.large]: '0_half',
								}}
							>
								<Stat
									number="0"
									border={true}
									title="Awards Showcased"
									description="Product success should not be measured by awards. We focus on meeting outcomes, performance metrics and real-world user needs."
								/>
							</Cell>
						</Row>
					</Container>
				</div>
			</Slide>
			<Slide prefferedTheme={THEME_PERIWINKLE}>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--large">
								We invent elegant, purposeful products and systems that move
								people and businesses forward.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									We build cross-functional teams with a range of diverse
									perspectives and design skills.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutIndented>
							<div
								css={{
									// 1 up
									[media.small]: buildLayoutVCadenceStyle({
										size: LAYOUT_V_CADENCE_SIZE_MEDIUM,
										cols: 1,
									}),
									[media.medium]: buildLayoutVCadenceStyle({
										size: LAYOUT_V_CADENCE_SIZE_MEDIUM,
										cols: 1,
									}),
									[media.large]: {
										display: 'flex',
										justifyContent: 'space-between',
									},
								}}
							>
								<Text>
									<ul>
										<li>Strategic Planning</li>
										<li>Strategy Validation</li>
										<li>UX Research</li>
										<li>Usability Testing</li>
										<li>Product Leadership</li>
									</ul>
								</Text>
								<Text>
									<ul>
										<li>IA & UX Design</li>
										<li>UI & Visual Design</li>
										<li>Prototyping</li>
										<li>Brand ID & Visual Systems</li>
										<li>System & Guideline Design</li>
									</ul>
								</Text>
								<Text>
									<ul>
										<li>Technical Strategy</li>
										<li>Functional Prototyping </li>
										<li>UI Libraries</li>
										<li>Design Tools & Plug-Ins</li>
										<li>Design System Platforms</li>
									</ul>
								</Text>
							</div>
						</LayoutIndented>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				style={{
					[media.small]: {
						paddingTop: 0,
					},
				}}
			>
				<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_EXTRA_LARGE}>
						<Container coastToCoastSmall={true}>
							<Img fluid={data.breakerImage.childImageSharp.fluid} />
						</Container>
						<Container>
							<LayoutIndented>
								<Text>
									<p>
										Our team shares a set of values that guide how we work,
										create and live everyday.
									</p>
								</Text>
							</LayoutIndented>
						</Container>
					</LayoutVerticalCadence>
					<Container>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Heart">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Find enjoyment in each day. Bring enthusiasm and courage to
										each challenge. Never be bored. The journey is the
										destination, so enjoy the ride.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Perspective">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Hone your craft and gain perspective. Experience a range of
										design challenges varying in scale, industry, medium and
										setting. Check your ego and open your mind to many points of
										view.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Invention">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Create better ways of working. Embrace complex problems with
										lateral thinking. Experiment rapidly with a mindset rooted
										in the scientific process. Keep user needs in view. Heart of
										an artist, mind of a scientist.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Clarity">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Strive for absolute clarity in thinking, writing and
										speaking. Be thoughtful, careful, and persistent in your
										work. Define clear objectives and success measures for
										client partners, their products and yourself.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Flow">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Focus and immerse yourself in your work. The best teams push
										beyond personal pride and work in harmony together towards
										ambitious outcomes. Collaborate with creativity and
										humility. Grow and be happy. Focus determines reality.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</Container>
				</LayoutVerticalCadence>
			</Slide>
		</div>
	)
}

export default provideAccentTheme(CompanyPage, THEME_PERIWINKLE)
